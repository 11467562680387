import { WppButton, WppNavSidebar } from '@wppopen/components-library-react'
import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { useCreateRfi } from 'api/mutations/rfis/useCreateRfi'
import useProjectContext from 'hooks/useProjectContext'

import styles from './SidebarCmp.module.scss'
import { NavigationItems } from './SidebarCmpNavItems'

export const navigation = {
  home: '/rfi-helper-tool/home',
  newProject: '/rfi-helper-tool/new-project',
  noAccess: '/rfi-helper-tool/no-access',
  agencyDashboard: '/rfi-helper-tool/agency-dashboard',
  projectDetails: '/rfi-helper-tool/project-detail',
  rfiSummary: '/rfi-helper-tool/rfi-summary',
  relevantNotes: '/rfi-helper-tool/relevant-notes',
  // proposedResponseStructure: '/rfi-helper-tool/proposed-response-structure',
  answerQuestions: '/rfi-helper-tool/answer-questions',
  draftResponse: '/rfi-helper-tool/ai-draft-response',
}

export function SidebarCmp() {
  const navigate = useNavigate()
  const [activePathname, setActivePathname] = useState('')
  const { projectId, agencyId } = useParams()
  const location = useLocation()
  const saveProject = useCreateRfi()
  const { state } = useProjectContext()

  const firstProjectItem = navigation.projectDetails
  // const lastItem = navigation.draftResponse

  useEffect(() => {
    setTimeout(() => {
      setActivePathname(location.pathname)
    }, 0)
  }, [location.pathname])

  if ([navigation.home, navigation.agencyDashboard].includes(location.pathname) || agencyId) {
    return null
  }

  const handleChangeRoute = (event: CustomEvent) => {
    navigate(event.detail.path)
  }

  const handleNext = () => {
    const index = Object.values(navigation).findIndex(path => location.pathname.includes(path))
    if (!projectId) return
    navigate(`${Object.values(navigation)[index + 1]}/${projectId}`)
  }

  const handleBack = () => {
    const index = Object.values(navigation).findIndex(path => location.pathname.includes(path))
    navigate(`${Object.values(navigation)[index - 1]}/${projectId}`)
  }
  if (!projectId && [navigation.home, navigation.noAccess].indexOf(location.pathname) !== -1) {
    return null
  }

  const disableItems = clsx(!projectId ? 'opacity-50 hover:cursor-not-allowed pointer-events-none' : '')
  const disabledBack = activePathname.includes(navigation.newProject) || activePathname.includes(firstProjectItem)
  let disabledNext = activePathname.includes(navigation.newProject) || state.newTaskStarted !== null

  return (
    <div className={styles.sidebarContainer} data-rfi-sidebar>
      <WppNavSidebar className={styles.sidebar} onWppChange={handleChangeRoute}>
        <NavigationItems
          navigation={navigation}
          newTaskStarted={Boolean(state.newTaskStarted)}
          disableItems={disableItems}
          projectId={projectId || ''}
          active={activePathname}
        />
        <div className="h-8" />
        {!location.pathname.includes(navigation.home) && (
          <div className="flex flex-row justify-end items-center gap-3 absolute bottom-0 right-3">
            <WppButton onClick={handleBack} disabled={disabledBack} variant="secondary">
              Back
            </WppButton>
            <WppButton disabled={disabledNext} loading={saveProject.isPending} onClick={handleNext}>
              {location.pathname.includes(navigation.draftResponse) ? 'Done' : 'Next'}
            </WppButton>
          </div>
        )}
      </WppNavSidebar>
    </div>
  )
}
