import { AnalyticsActionType } from '@wppopen/core'
import { useOs } from '@wppopen/react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { useGetAgencies } from 'api/queries/agencies/useGetAgencies'
import { useRfis } from 'api/queries/rfis/useRfis'
import { agencyRoles } from 'constants/agencyRoles'

import EmptyStateHome from './EmptyStateHome'
import { ProjectDashboardView } from './ProjectDashboardView'
import { trackAnalytics, ANALYTICS_EVENTS } from '../../utils/analytics'

export interface SetApifilterProps {
  clientIds?: string[]
  marketIds?: string[]
  pitchTypeIds?: string[]
}
export default function HomePage() {
  const navigate = useNavigate()
  const { osContext } = useOs()
  const {
    data: projects = [],
    error,
    isLoading,
    isFetching,
  } = useRfis({
    staleTime: 60 * 10 * 1000, // 10 minutes
  })

  const agencyPermission = osContext.permissions?.[0]?.permissions.includes(agencyRoles.RFI_AGENCY_MANAGE)
  // add to cache
  useGetAgencies()
  const isEmpty = !isLoading && (error || (!error && !projects.length))

  useEffect(() => {
    trackAnalytics({
      type: AnalyticsActionType.page,
      payload: ANALYTICS_EVENTS.MY_PROJECTS_PAGE_VIEW,
    })
  }, [])

  if (isEmpty) return <EmptyStateHome />
  return (
    <ProjectDashboardView
      allProjectsData={projects}
      projects={projects}
      navigate={navigate}
      isLoading={isLoading}
      isFetching={isFetching}
      error={error}
      agencyPermission={agencyPermission}
    />
  )
}
