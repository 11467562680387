import { PaginationChangeEventDetail, SelectChangeEventDetail } from '@wppopen/components-library'
import {
  WppListItem,
  WppModal,
  WppPagination,
  WppSelect,
  WppPill,
  WppSpinner,
  WppTypography,
  WppTooltip,
} from '@wppopen/components-library-react'
import { useEffect, useState, useRef, useCallback } from 'react'

import { usePitchTypes } from 'api/queries/pitch-types/usePitchTypes'
import { useRfis } from 'api/queries/rfis/useRfis'
import { ProjectsFilterGroup } from 'components/ProjectsFilterGroup'
import { ProjectDetails } from 'types/rfis/rfi'

import { ProjectDeleteModal } from './ProjectDeleteModal'
import ProjectPreviewCard from './ProjectPreviewCard'
import { useProjectFilterSelects, valuePillsName } from './utils'

export const paginated = (page: number, currPage: number, pageSize: number, data: ProjectDetails[]) =>
  data.slice(page * pageSize, currPage * pageSize)

export enum ControlValues {
  ALL = 'All',
  MY_PROJECTS = 'My projects',
  SHARED_WITH_ME = 'Shared with me',
}

interface IProjectDashboardView {
  navigate: any
  projects: ProjectDetails[]
  isLoading: boolean
  isFetching: boolean
  error: any
  agencyPermission: boolean
  allProjectsData: ProjectDetails[]
}

export interface ActiveFilters {
  clientIds?: string
  marketIds?: string
  pitchTypeIds?: string
  createdBys?: string
  lastUpdatedBys?: string
}

export const ProjectDashboardView = ({
  navigate,
  allProjectsData,
  projects,
  isLoading,
  isFetching,
  error,
  agencyPermission,
}: IProjectDashboardView) => {
  const initPageSize = 9
  const [activeFilters, setActiveFilters] = useState<ActiveFilters>({})

  const [appliedApiFilters, setAppliedFilters] = useState({
    /* like this: */
    // params: {
    //   query: {},
    // },
  })
  const {
    data: filteredProjects = [],
    // error: errorFiltering,
    isLoading: isLoadingFiltering,
    isFetching: isFetchingFiltering,
  } = useRfis(appliedApiFilters)
  const [displayData, setDisplayData] = useState<ProjectDetails[]>(filteredProjects || allProjectsData)

  const [paginationPageSize, setPaginationPageSize] = useState<number>(initPageSize)
  const [currPage, setCurrPage] = useState<number>(1)
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false)
  const [selectedProject, setSelectedProject] = useState<{ id: string; name: string }>({ id: '', name: '' })
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [clientNamesSelectValue, setClientNamesSelectValue] = useState<string[]>([])
  const [agencieSelectValues, setAgencieSelectValues] = useState<string[]>([])
  const [pitchTypeSelectValues, setPitchTypeSelectValues] = useState<string[]>([])
  const [marketsSelectValues, setMarketsSelectValues] = useState<string[]>([])
  const [createBySelectValues, setCreateBySelectValues] = useState<string[]>([])
  const [lastUpdatedBySelectValues, setLastUpdatedBySelectValues] = useState<string[]>([])
  const [controlValue, setControlValue] = useState<ControlValues>(ControlValues.ALL)
  const filterGroupContainerRef = useRef<HTMLDivElement>(null)

  const { data: allPitchTypes } = usePitchTypes()
  let {
    clients,
    markets,
    pitches = [],
    createdBys,
    lastUpdatedBys,
    agencies,
  } = useProjectFilterSelects(allProjectsData, allPitchTypes, controlValue)
  const isFiltering = isLoadingFiltering || isFetchingFiltering

  const handleResetFilters = () => {
    setActiveFilters({})
    setAppliedFilters({})
    handleCancelFilters()
  }

  const handleApplyFilters = (activeFilters = {}) => {
    if (marketsSelectValues.length > 0) {
      activeFilters = {
        ...activeFilters,
        marketIds: marketsSelectValues.join(','),
      }
    }
    if (clientNamesSelectValue.length > 0) {
      activeFilters = {
        ...activeFilters,
        clientIds: clientNamesSelectValue.join(','),
      }
    }
    if (pitchTypeSelectValues.length > 0) {
      activeFilters = {
        ...activeFilters,
        pitchTypeIds: pitchTypeSelectValues.join(','),
      }
    }
    if (createBySelectValues.length > 0) {
      activeFilters = {
        ...activeFilters,
        createdBys: createBySelectValues.join(','),
      }
    }
    if (lastUpdatedBySelectValues.length > 0) {
      activeFilters = {
        ...activeFilters,
        lastUpdatedBys: lastUpdatedBySelectValues.join(','),
      }
    }
    applyAllFilters(activeFilters)
  }

  const applyAllFilters = (activeFilters: ActiveFilters) => {
    if (Object.keys(activeFilters).length > 0) {
      const params = {
        query: {
          ...activeFilters,
        },
      }
      setAppliedFilters({ params })
    } else {
      setAppliedFilters({})
    }
    setActiveFilters(activeFilters)
  }

  const handleCancelFilters = () => {
    setMarketsSelectValues(activeFilters?.marketIds?.split(',') || [])
    setClientNamesSelectValue(activeFilters?.clientIds?.split(',') || [])
    setPitchTypeSelectValues(activeFilters?.pitchTypeIds?.split(',') || [])
    setCreateBySelectValues(activeFilters?.createdBys?.split(',') || [])
    setLastUpdatedBySelectValues(activeFilters?.lastUpdatedBys?.split(',') || [])
  }

  /* apply client side filters!!  */
  useEffect(() => {
    if (!isLoading && !isFetching && !isFiltering && searchTerm === '' && agencieSelectValues.length === 0) {
      let result = filteredProjects

      setDisplayData(result)
    }
  }, [displayData, filteredProjects, isLoading, isFetching, isFiltering, agencieSelectValues, agencies, searchTerm])

  /* Search */
  const handleSearchChange = (value: string) => setSearchTerm(value)

  useEffect(() => {
    if (searchTerm?.length > 1) {
      const searchResults = displayData.filter(project =>
        project?.projectName.toLowerCase().includes(searchTerm.toLowerCase()),
      )
      setDisplayData(searchResults)
    }
  }, [displayData, searchTerm])

  /* Pagination */
  const isPaginated = displayData.length > initPageSize && !isFiltering && !isLoading && !isFetching
  let perPageOptions = [initPageSize]
  displayData.length > 9 && perPageOptions.push(12)
  displayData.length > 12 && perPageOptions.push(15)
  const handlePaginationChange = (event: CustomEvent<PaginationChangeEventDetail>) => {
    const { itemsPerPage, page } = event.detail
    if (paginationPageSize !== itemsPerPage) {
      setPaginationPageSize(itemsPerPage)
    }
    if (currPage !== page) {
      setCurrPage(page)
    }
  }

  const fetchingAll = isLoading || isFetching || isFiltering

  const handleAgencySelectChange = useCallback(
    (e: CustomEvent<SelectChangeEventDetail>) => {
      setAgencieSelectValues(e.detail.value)

      // filter in all display data with an agency id in the e.detail.value id arraay
      const newDisplayData = allProjectsData.filter(project =>
        project.agencies.some(agency => e.detail.value.includes(agency.id)),
      )
      setDisplayData(newDisplayData)
    },
    [allProjectsData],
  )

  return (
    <div className="px-4">
      <div className="flex justify-start mb-4">
        <WppTypography type="2xl-heading">My Projects</WppTypography>
      </div>
      <div className="flex justify-center mb-4" ref={filterGroupContainerRef}>
        <ProjectsFilterGroup
          navigate={navigate}
          agencyPermission={agencyPermission}
          disabled={isLoading || isFetching || isFiltering}
          handleApplyFilters={handleApplyFilters}
          handleResetFilters={handleResetFilters}
          handleCancelFilters={handleCancelFilters}
          handleSearchChange={handleSearchChange}
          controlValue={controlValue}
          setFilterControlValue={setControlValue}
          agencyDropdown={
            <>
              <WppSelect
                aria-label="Agencies"
                className="w-60"
                title="Agencies"
                placeholder="Agency"
                size="s"
                type="multiple"
                disabled={isFiltering || isLoading || isFetching}
                withSearch
                withFolder
                value={agencieSelectValues}
                onWppChange={handleAgencySelectChange}
              >
                {(agencies as { name: string; id: string }[])?.map(item => (
                  <WppListItem key={item.id} value={item.id}>
                    <p slot="label">{item.name}</p>
                  </WppListItem>
                ))}
              </WppSelect>
            </>
          }
          filters={
            <>
              <WppSelect
                aria-label="Markets"
                title="Markets"
                placeholder="Select markets"
                type="multiple"
                labelConfig={{
                  text: 'Markets',
                }}
                withSearch
                withFolder
                value={[...marketsSelectValues]}
                onWppChange={e => setMarketsSelectValues(e.detail.value)}
              >
                {(markets as { market: string; id: string }[])?.map(item => (
                  <WppListItem key={item.id} value={item.id}>
                    <p slot="label">{item.market}</p>
                  </WppListItem>
                ))}
              </WppSelect>
              <WppSelect
                aria-label="Clients"
                title="Clients"
                placeholder="Select clients"
                type="multiple"
                labelConfig={{
                  text: 'Clients',
                }}
                withFolder
                withSearch
                value={clientNamesSelectValue}
                onWppChange={e => {
                  const clientValues = e.detail.value.reduce(
                    (accu: string[], item: { name: string; id: string } | string) => {
                      if (typeof item === 'string') {
                        accu.push(item)
                      }
                      return accu
                    },
                    [],
                  )
                  setClientNamesSelectValue(clientValues)
                }}
              >
                {clients?.map(item => (
                  <WppListItem key={item.id} value={item.id}>
                    <p slot="label">{item.name}</p>
                  </WppListItem>
                ))}
              </WppSelect>
              <WppSelect
                aria-label="Pitch type"
                title="Pitch type"
                placeholder="Select pitch type"
                type="multiple"
                labelConfig={{
                  text: 'Pitch type',
                }}
                withSearch
                value={pitchTypeSelectValues}
                withFolder
                onWppChange={e => setPitchTypeSelectValues(e.detail.value)}
              >
                {pitches?.map(item => (
                  <WppListItem key={item.id} value={item.id}>
                    <p slot="label">{item.name}</p>
                  </WppListItem>
                ))}
              </WppSelect>
              <WppSelect
                aria-label="Created by"
                title="Created by"
                placeholder="Select created by"
                type="multiple"
                labelConfig={{
                  text: 'Created by',
                }}
                withSearch
                withFolder
                value={createBySelectValues}
                onWppChange={e => setCreateBySelectValues(e.detail.value)}
                dropdownConfig={{ placement: 'top' }}
              >
                {createdBys?.map(item => (
                  <WppListItem key={item.email} value={item.email}>
                    <p slot="label">{item.name}</p>
                  </WppListItem>
                ))}
              </WppSelect>
              <WppSelect
                aria-label="Updated by"
                title="Updated by"
                placeholder="Select updated by"
                type="multiple"
                labelConfig={{
                  text: 'Updated by',
                }}
                withSearch
                withFolder
                disabled={lastUpdatedBys.length === 0}
                value={lastUpdatedBySelectValues}
                onWppChange={e => {
                  setLastUpdatedBySelectValues(e.detail.value)
                }}
              >
                {lastUpdatedBys?.map(item => (
                  <WppListItem key={item.email} value={item.email}>
                    <p slot="label">{item.name}</p>
                  </WppListItem>
                ))}
              </WppSelect>
            </>
          }
        />
      </div>
      <div className="flex justify-start gap-4 mb-4">
        <>
          {Object.entries(activeFilters).map(([key]) => {
            const { formatted, item } = valuePillsName({
              pitches,
              clients,
              value: key,
              markets,
              activeFilters,
              createdBys,
              lastUpdatedBys,
            })
            return (
              <WppTooltip text={`${item}: ${formatted}`} key={Math.random().toString()}>
                <WppPill
                  disabled={isFiltering}
                  label={`${item}`}
                  removable
                  value={item}
                  onWppClose={() => {
                    const newActiveFilters = { ...activeFilters }
                    if (key === 'marketIds') {
                      setMarketsSelectValues([])
                      delete newActiveFilters.marketIds
                    }
                    if (key === 'clientIds') {
                      setClientNamesSelectValue([])
                      delete newActiveFilters.clientIds
                    }
                    if (key === 'pitchTypeIds') {
                      setPitchTypeSelectValues([])
                      delete newActiveFilters.pitchTypeIds
                    }
                    if (key === 'createdBys') {
                      setCreateBySelectValues([])
                      delete newActiveFilters.createdBys
                    }
                    if (key === 'lastUpdatedBys') {
                      setLastUpdatedBySelectValues([])
                      delete newActiveFilters.lastUpdatedBys
                    }
                    applyAllFilters(newActiveFilters)
                  }}
                  type="display"
                />
              </WppTooltip>
            )
          })}
        </>
      </div>
      {!error && fetchingAll && (
        <div className="flex flex-row items-start justify-center h-52">
          <WppSpinner size="l" />
        </div>
      )}
      {!error && displayData && !fetchingAll && (
        <div className={`${isPaginated ? '' : 'pb-16'} grid grid-cols-3 gap-6 relative`}>
          {paginated(currPage - 1, currPage, paginationPageSize, displayData)?.map((project: any) => (
            <ProjectPreviewCard
              key={project.id}
              {...project}
              handleSelectedProject={project => {
                setIsEditModalOpen(true)
                setSelectedProject(project)
              }}
              closeDeleteModal={() => setIsEditModalOpen(false)}
            />
          ))}
          {displayData.length === 0 && !error && !isFetching && !isLoading && !isFiltering && 'No results found'}
        </div>
      )}
      <WppModal open={isEditModalOpen} onWppModalClose={() => setIsEditModalOpen(false)} size="s" disableOutsideClick>
        <ProjectDeleteModal
          projectId={selectedProject?.id || ''}
          name={selectedProject?.name || ''}
          handleModalClose={() => {
            setIsEditModalOpen(false)
          }}
        />
      </WppModal>

      {isPaginated && (
        <WppPagination
          count={projects.length}
          itemsPerPage={perPageOptions}
          activePageNumber={currPage}
          pageSelectThreshold={5}
          data-testid="pagination-list"
          selectedItemPerPage={paginationPageSize}
          onWppChange={handlePaginationChange}
          className="pb-16 mt-4 mb-4"
        />
      )}
    </div>
  )
}
