import { ListItemChangeEventDetail } from '@wppopen/components-library'
import { WppListItemCustomEvent } from '@wppopen/components-library/dist/types/components'
import {
  WppAvatar,
  WppTypography,
  WppDivider,
  WppAvatarGroup,
  WppListItem,
  WppIconTrash,
  WppTooltip,
  WppCard,
  WppActionButton,
  WppIconMore,
  WppMenuContext,
  WppIconEdit,
} from '@wppopen/components-library-react'
import { AnalyticsActionType } from '@wppopen/core'
import { Fragment, ReactNode, SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react'
import { LinkProps, useNavigate } from 'react-router-dom'

import { usePitchTypes } from 'api/queries/pitch-types/usePitchTypes'
import { navigation } from 'components/SidebarCmp'
import useProjectContext from 'hooks/useProjectContext'
import { CreatedOrUpdatedBy, RfiAgency, RfiMember } from 'types/rfis/rfi'
import { useGetMarketsByIds } from 'utils/projectUtils'

import style from './home.module.scss'
import { trackAnalytics, ANALYTICS_EVENTS } from '../../utils/analytics'

export default function ProjectPreviewCard({
  projectName,
  agencies,
  pitchTypeId,
  updatedAt,
  createdAt,
  createdBy,
  marketIds,
  projectMembers,
  id,
  rfiSummary,
  clientName,
  handleSelectedProject,
}: Readonly<{
  projectName: string
  activeStatus: number
  clientName: string
  pitchTypeId: string
  updatedAt: string
  createdAt: string
  createdBy: CreatedOrUpdatedBy
  isCompleted: boolean
  id: string
  agencies: RfiAgency[]
  projectMembers: RfiMember[]
  marketIds: string[]
  rfiSummary: string
  handleSelectedProject: ({ id, name }: { id: string; name: string }) => void
}>) {
  const navigate = useNavigate()
  const { data: pitchTypes } = usePitchTypes()
  const { setState } = useProjectContext()
  const listContainerRef = useRef<HTMLDivElement>(null)
  const titleRef = useRef<HTMLDivElement | null>(null)
  const containerRef = useRef<LinkProps | null>(null)
  const [truncate, setTruncate] = useState(false)
  const [containerWidth, setContainerWidth] = useState(0)
  const rightSidePadding = 40

  const pitchType = pitchTypes?.find(p => p.id === pitchTypeId)?.typeDescription
  const markets = useGetMarketsByIds(marketIds, false) as string[] | undefined

  const agenciesString =
    agencies?.reduce((accum, agency, index, arr) => {
      const isLastOrFirstOfOnly1 = index === arr.length - 1 || (index === 0 && arr.length === 1)
      accum += `${agency?.name}${!isLastOrFirstOfOnly1 ? ', ' : ''}` || ''
      return accum
    }, '') || ''

  const handleEditProjectClick = (e: SyntheticEvent | WppListItemCustomEvent<ListItemChangeEventDetail>) => {
    if (
      (e?.target as HTMLElement)?.dataset?.menu ||
      (e?.target as HTMLElement).textContent?.toLowerCase()?.includes('delete') // TODO translation key
    ) {
      return
    }
    e.preventDefault()
    setState(prev => ({
      ...prev,
      projectId: id,
      rfiSummary,
    }))

    trackAnalytics({
      type: AnalyticsActionType.page,
      payload: ANALYTICS_EVENTS.PROJECT_DETAILS_PAGE_VIEW,
    })

    navigate(`${navigation.projectDetails}/${id}`)
  }

  const handleContainerWidth = useCallback(() => {
    /*
     * Get the width of the container
     * and set the width of the list items to match
     * the width of the container
     * by accessing the shadowRoot of the list items
     * because WPP Open Components are built on StencilJS which is a web component library
     * - deep in the component the tooltip is setting the width incorrectly
     * it needs to be dynamic and 100% will not work
     */
    if (listContainerRef.current) {
      const listItems = Array.from(listContainerRef.current.querySelectorAll('[data-truncated-item]'))
      listItems.forEach(item => {
        const shadowRoot = item.shadowRoot
        item.setAttribute('style', `width: ${listContainerRef?.current?.offsetWidth?.toString() || '0'}px!important`)
        // find the li element in the shadowroot
        const lis = shadowRoot?.querySelectorAll('li')
        if (lis) {
          Array.from(lis).forEach(li => {
            const tooltip = li?.querySelector('.tooltip')
            const bodyWrapper = tooltip?.querySelector('.body-wrapper')
            if (bodyWrapper) {
              bodyWrapper?.setAttribute(
                'style',
                `width: ${listContainerRef?.current?.offsetWidth?.toString() || '0'}px!important`,
              )
            }
            // make the font color pink
            li?.setAttribute('style', `width: ${listContainerRef?.current?.offsetWidth?.toString() || '0'}px!important`)
          })
        }
      })
    }
  }, [])

  const handleTruncate = useCallback(() => {
    setContainerWidth((containerRef.current as HTMLElement | null)?.clientWidth || 0)
    // get the width of the containerRef.current
    const containerWidth = (containerRef.current as HTMLElement | null)?.clientWidth || 0
    /*
     * Create an inline element to hold the text
     * and set the font style to match the span
     * so that the width of the span will match the width of the span
     * and we can get the width of the text
     * to determine if it needs to be truncated
     * NB: due to nested Web Components and shadow DOM issues
     */
    const span = document.createElement('span')
    // Set the font style of the span
    span.style.fontSize = '16px'
    span.style.opacity = '0'
    // Append the text to the span
    span.textContent = `${projectName}`
    // Append the span to the document body (it will be hidden)
    document.body.appendChild(span)
    // Get the width of the span
    const width = span.offsetWidth
    // Remove the span
    document.body.removeChild(span)

    if (width > containerWidth - rightSidePadding * 2) {
      setTruncate(true)
    }
  }, [projectName])

  useEffect(() => {
    if (titleRef?.current && containerRef?.current && markets) {
      setTimeout(() => {
        handleTruncate()
      }, 150)
      window.addEventListener('resize', handleTruncate)
    }
    return () => {
      window.removeEventListener('resize', handleTruncate)
    }
  }, [containerWidth, handleTruncate, markets])

  useEffect(() => {
    if (containerRef.current && markets) {
      setTimeout(() => {
        handleContainerWidth()
      }, 200)
      window.addEventListener('resize', handleContainerWidth)
    }
    return () => {
      window.removeEventListener('resize', handleContainerWidth)
    }
  }, [handleContainerWidth, markets])

  const Component = truncate
    ? ({ children }: { children: ReactNode }) => <WppTooltip text={projectName}>{children}</WppTooltip>
    : Fragment

  return (
    <>
      <WppCard interactive className="w-full min-w-80" onClick={handleEditProjectClick}>
        <div ref={containerRef as React.RefObject<HTMLDivElement>} className="flex flex-row justify-between relative">
          <div ref={titleRef}>
            <Component>
              <WppTypography
                type="xl-heading"
                className={style.headingTruncation}
                style={{
                  width: `${containerWidth - rightSidePadding}px`,
                }}
              >
                {projectName}
              </WppTypography>
            </Component>
          </div>
          <div slot="actions">
            <WppMenuContext data-menu>
              <WppActionButton slot="trigger-element" data-menu>
                <WppIconMore direction="horizontal" data-menu />
              </WppActionButton>
              <div>
                <WppListItem onWppChangeListItem={handleEditProjectClick}>
                  <p slot="label">
                    <WppIconEdit className="absolute left-4" />
                    <span className="pl-8">Edit</span>
                  </p>
                </WppListItem>
                <WppListItem
                  onWppChangeListItem={e => {
                    e.stopPropagation() // not working
                    handleSelectedProject({ id, name: projectName })
                  }}
                >
                  <p slot="label">
                    <WppIconTrash className="absolute left-4" />
                    <span className="pl-8">Delete</span>
                  </p>
                </WppListItem>
              </div>
            </WppMenuContext>
          </div>
        </div>
        <div className="mt-auto" ref={listContainerRef}>
          <WppListItem selectable={false} className={style.listItem} data-truncated-item>
            <p slot="label">
              <WppTypography type="s-midi" className="text-grey">
                Last saved:{'  '}
              </WppTypography>
              <span>{new Date(updatedAt || createdAt).toLocaleString()} </span>
            </p>
          </WppListItem>
          <WppListItem selectable={false} className={style.listItem} data-truncated-item>
            <p slot="label">
              <WppTypography type="s-midi" className="text-grey">
                Agencies:{'  '}
              </WppTypography>
              <span className="pl-2">{agenciesString}</span>
            </p>
          </WppListItem>
          <WppListItem selectable={false} className={style.listItem} data-truncated-item>
            <p slot="label">
              <WppTypography type="s-midi" className="text-grey">
                Client:{' '}
              </WppTypography>
              <span className="pl-2">{clientName}</span>
            </p>
          </WppListItem>
          {markets && markets.length && (
            <WppListItem selectable={false} className={style.listItem} data-truncated-item>
              <p slot="label">
                <WppTypography type="s-midi" className="text-grey">
                  Market:{'  '}
                </WppTypography>
                <span className="pl-2">{markets}</span>
              </p>
            </WppListItem>
          )}
          <WppListItem selectable={false} className={style.listItem} data-truncated-item>
            <p slot="label">
              <WppTypography type="s-midi" className="text-grey">
                Pitch Type:
              </WppTypography>{' '}
              <span className="pl-2">{pitchType}</span>
            </p>
          </WppListItem>
          <WppListItem selectable={false} className={style.listItem} data-truncated-item>
            <p slot="label">
              <WppTypography type="s-midi" className="text-grey">
                Created By:{' '}
              </WppTypography>
              <span className="pl-2">
                {' '}
                <WppAvatar src={createdBy?.img ?? ''} name={createdBy?.name || ''} /> {createdBy?.name}
              </span>
            </p>
          </WppListItem>
          <WppDivider className="mt-2 mb-2" />
          <div className="flex items-end">
            {projectMembers && (
              <WppAvatarGroup
                className="pt-2"
                maxAvatarsToDisplay={3}
                size="xs"
                withTooltip
                avatars={projectMembers?.map(member => ({
                  name: member.memberDetail.name,
                  src: member.memberDetail.img ?? '',
                }))}
              />
            )}
            {/* {Boolean(activeStatus) && (
            <WppTag className="ml-auto" variant="positive" label={activeStatus === 1 ? 'Done' : 'In Progress'} />
          )} */}
          </div>
        </div>
      </WppCard>
    </>
  )
}
