import { WppIconCross, WppIconDocument, WppModal, WppTypography } from '@wppopen/components-library-react'
import { useState } from 'react'

import { AgencyDeleteFileModal } from './AgencyDeleteFileModal'
import style from './AgencyFileList.module.scss'
import { bytesToMB } from './utils'

interface AgencyFileListProps {
  agencyFiles: {
    id: string
    name: string
    size: number
  }[]
}

const AgencyFileList = ({ agencyFiles }: AgencyFileListProps) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)

  const handleDeleteAgencyFileClick = () => {
    setIsEditModalOpen(true)
  }

  return (
    <>
      {agencyFiles.length > 0 && (
        <div className={style.fileListContainer}>
          {agencyFiles.map((file, index) => (
            <div key={file.id || index}>
              <div className={style.uploadedFileWrapper}>
                <div className={style.leftContent}>
                  <WppIconDocument color="#4D5358" />
                  <WppTypography type="s-body" className={style.fileName}>
                    {file.name}
                  </WppTypography>
                  <WppTypography type="s-body" className={style.fileSize}>
                    {bytesToMB(file.size)}
                  </WppTypography>
                </div>
                <div className={style.rightContent}>
                  <WppIconCross
                    color="#8B919A"
                    onClick={() => handleDeleteAgencyFileClick()}
                    className={style.closeIcon}
                  />
                </div>
              </div>
              <WppModal open={isEditModalOpen} onWppModalClose={() => setIsEditModalOpen(false)} size="s">
                <AgencyDeleteFileModal
                  fileId={file.id}
                  handleModalClose={() => {
                    setIsEditModalOpen(false)
                  }}
                />
              </WppModal>
            </div>
          ))}
        </div>
      )}
    </>
  )
}

export default AgencyFileList
