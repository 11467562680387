import { useOs } from '@wppopen/react'

import { PitchType } from 'types/pitch-types/pitchType'
import { ProjectDetails } from 'types/rfis/rfi'
import { useGetMarketsByIds } from 'utils/projectUtils'

import { ActiveFilters, ControlValues } from './ProjectDashboardView'
export interface ProjectFilterSelectsReturn {
  clients: { name: string; id: string }[]
  pitches: { id: string; name: string }[]
  createdBys: { name: string; email: string }[]
  lastUpdatedBys: { name: string; email: string }[]
  agencies: { name: string; id: string }[]
  markets: { id: string; market: string }[]
}

export const findMarketNameInMarkets = (markets: { market: string; id: string }[], marketIds: string[]) =>
  markets
    ?.filter(market => marketIds?.includes(market.id))
    ?.map(market => market.market)
    ?.join(', ') || 'names not found'

export const findClientsInClientsFromClientIds = (clients: { name: string; id: string }[], clientIds: string[]) =>
  clients
    ?.filter(client => clientIds?.includes(client.id))
    ?.map(client => client.name)
    ?.join(', ') || 'names not found'

export const findPitchesInPitchesFromPitchIds = (pitches: { id: string; name: string }[], pitchIds: string[]) =>
  pitches
    ?.filter(pitch => pitchIds?.includes(pitch.id))
    ?.map(pitch => pitch.name)
    ?.join(', ') || 'names not found'

export const findCreatedByNamesInCreatedBys = (
  AllcreatedBys: { name: string; email: string }[],
  createdBys: string[],
) =>
  AllcreatedBys?.filter(createdBy => createdBys?.includes(createdBy.email))
    ?.map(createdBy => createdBy.name)
    ?.join(', ') || 'names not found'

export const findLastUpdatedByNamesInLastUpdatedBys = (
  allLastUpdatedBys: { name: string; email: string }[],
  lastUpdatedBys: string[],
) =>
  allLastUpdatedBys
    ?.filter(lastUpdatedBy => lastUpdatedBys?.includes(lastUpdatedBy.email))
    ?.map(lastUpdatedBy => lastUpdatedBy.name)
    ?.join(', ') || 'names not found'

export const valuePillsName = ({
  value,
  activeFilters,
  markets,
  clients,
  pitches,
  createdBys,
  lastUpdatedBys,
}: {
  markets: { market: string; id: string }[]
  clients: { name: string; id: string }[]
  pitches: { id: string; name: string }[]
  value: string
  activeFilters: ActiveFilters
  createdBys: { name: string; email: string }[]
  lastUpdatedBys: { name: string; email: string }[]
}) => {
  const valueMap: {
    [key: string]: {
      item: string
      formatted?: string
    }
  } = {
    marketIds: {
      item: 'Markets',
      formatted: findMarketNameInMarkets(markets, activeFilters.marketIds?.split(',') || []) || '',
    },
    clientIds: {
      item: 'Clients',
      formatted: findClientsInClientsFromClientIds(clients, activeFilters.clientIds?.split(',') || []) || '',
    },
    pitchTypeIds: {
      item: 'Pitch',
      formatted: pitches ? findPitchesInPitchesFromPitchIds(pitches, activeFilters.pitchTypeIds?.split(',') || []) : '',
    },
    createdBys: {
      item: 'Created by',
      formatted: findCreatedByNamesInCreatedBys(createdBys, activeFilters.createdBys?.split(',') || []),
    },
    lastUpdatedBys: {
      item: 'Updated by',
      formatted: findLastUpdatedByNamesInLastUpdatedBys(lastUpdatedBys, activeFilters.lastUpdatedBys?.split(',') || []),
    },
  }

  return {
    ...valueMap[value],
  }
}

/* clientNames */
export const useProjectFilterSelects = (
  allProjectsData: ProjectDetails[],
  allPitchTypes: PitchType[],
  controlValue: ControlValues,
) => {
  const user = useOs()
  const { email } = user?.osContext?.userDetails

  if (controlValue === ControlValues.MY_PROJECTS && email) {
    allProjectsData = allProjectsData.filter(project => project.createdBy.email === email)
  }

  if (controlValue === ControlValues.SHARED_WITH_ME) {
    // filter in if user is a project member but did not create the project
    allProjectsData = allProjectsData.filter(
      project =>
        project.createdBy.email !== email &&
        project.projectMembers.some(member => member?.memberDetail.email === email),
    )
  }

  const marketIds = allProjectsData.map(project => project?.marketIds).flat()
  const marketOptions = useGetMarketsByIds(marketIds, true) || []
  //@ts-ignore
  const markets: { market: string; id: string }[] = Array.isArray(marketOptions)
    ? [...new Map(marketOptions.map(item => [item['market'], item])).values()].sort((a, b) =>
        //@ts-ignore
        a?.market.localeCompare(b?.market),
      )
    : []

  const clientNames = allProjectsData.map(project => ({ name: project.clientName, id: project.clientId }))
  const clientNameDeDupedById = [...new Map(clientNames.map(item => [item['id'], item])).values()].sort((a, b) =>
    a.name.localeCompare(b.name),
  )

  /* PitchTypes */
  const pitchTypeIds = allProjectsData.map(project => project?.pitchTypeId)
  const pitchIdsMapped =
    [...new Set(pitchTypeIds)]
      .map(id => ({
        id,
        name: allPitchTypes?.find(pitch => pitch.id === id)?.typeDescription || '',
      }))
      .sort((a, b) => String(a?.name).localeCompare(String(b?.name))) || []

  const createdBys = allProjectsData.map((project, index) => {
    const name = project.createdBy?.name || ''
    const email = project.createdBy?.email || ''
    return { name, email, id: index }
  })
  const createByDeDuped: { name: string; email: string }[] = [
    ...new Map(createdBys.map(item => [item['email'], item])).values(),
  ].sort((a, b) => a.name.localeCompare(b.name))
  const lastUpdatedBys = allProjectsData.map(project => project?.updatedBy).filter(item => item !== null)
  let lastUpdatedByDeDuped: { name: string; email: string }[] = []
  if (lastUpdatedBys.length > 0) {
    lastUpdatedByDeDuped = [...new Map(lastUpdatedBys.map(item => [item['email'], item])).values()].sort((a, b) =>
      a.name.localeCompare(b.name),
    )
  }

  const agencies = allProjectsData.map(project => project.agencies).flat()
  const agenciesDeduped: { name: string; id: string }[] = [
    ...new Map(agencies.map((item: { id: string; name: string }) => [item.id, item])).values(),
  ].sort((a, b) => a.name.localeCompare(b.name))

  return {
    clients: clientNameDeDupedById,
    pitches: pitchIdsMapped,
    createdBys: createByDeDuped,
    lastUpdatedBys: lastUpdatedByDeDuped,
    agencies: agenciesDeduped,
    markets,
  }
}
