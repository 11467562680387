// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Rj2Gr{display:flex;flex-direction:column;gap:8px;margin-top:8px}.e5H4b{display:flex;justify-content:space-between;padding:5px 8px}.LVmIm{display:flex;gap:8px}.RR4KU{display:flex}.NvClb::part(inner){color:var(--wpp-grey-color-900);font-family:Inter,sans-serif;font-size:14px;font-weight:400;line-height:22px;text-align:center}.iqcFv::part(inner){font-family:Inter,sans-serif;font-size:14px;font-weight:400;line-height:22px;text-align:center;color:var(--wpp-grey-color-700)}.Tc0FT:hover{cursor:pointer}`, "",{"version":3,"sources":["webpack://./src/pages/agency-dashboard/EditAddAgency/agencyFileList/AgencyFileList.module.scss"],"names":[],"mappings":"AAAA,OACE,YAAA,CACA,qBAAA,CACA,OAAA,CACA,cAAA,CAGF,OACE,YAAA,CACA,6BAAA,CACA,eAAA,CAGF,OACE,YAAA,CACA,OAAA,CAGF,OACE,YAAA,CAIA,oBACE,+BAAA,CACA,4BAAA,CACA,cAAA,CACA,eAAA,CACA,gBAAA,CACA,iBAAA,CAKF,oBACE,4BAAA,CACA,cAAA,CACA,eAAA,CACA,gBAAA,CACA,iBAAA,CACA,+BAAA,CAKF,aACE,cAAA","sourcesContent":[".fileListContainer {\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n  margin-top: 8px;\n}\n\n.uploadedFileWrapper {\n  display: flex;\n  justify-content: space-between;\n  padding: 5px 8px;\n}\n\n.leftContent {\n  display: flex;\n  gap: 8px;\n}\n\n.rightContent {\n  display: flex;\n}\n\n.fileName {\n  &::part(inner) {\n    color: var(--wpp-grey-color-900);\n    font-family: Inter, sans-serif;\n    font-size: 14px;\n    font-weight: 400;\n    line-height: 22px;\n    text-align: center;\n  }\n}\n\n.fileSize {\n  &::part(inner) {\n    font-family: Inter, sans-serif;\n    font-size: 14px;\n    font-weight: 400;\n    line-height: 22px;\n    text-align: center;\n    color: var( --wpp-grey-color-700);\n  }\n}\n\n.closeIcon {\n  &:hover {\n    cursor: pointer;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fileListContainer": `Rj2Gr`,
	"uploadedFileWrapper": `e5H4b`,
	"leftContent": `LVmIm`,
	"rightContent": `RR4KU`,
	"fileName": `NvClb`,
	"fileSize": `iqcFv`,
	"closeIcon": `Tc0FT`
};
export default ___CSS_LOADER_EXPORT___;
